import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import Layout from '../components/shared/Layout'
import SEO from '../components/shared/SEO'
import Container from '../components/shared/Container'

export default class extends React.Component {

  render() {
    
    return (
      <Layout>

        <SEO title="Future Entofarmers Hub" />          
        
        <Section style={tw`bg-primary`}>
          <Container>
            <p style={tw`bg-black inline text-sm rounded text-white py-1 px-2`}>announcement</p>
            <Title>Future Entofarmers Hub</Title>
            <Subtitle>Accelerating research and innovation in Controlled Environment Entofarming for Food</Subtitle>
          </Container>
        </Section>

        <Section>
          <Container>
            <p><strong>The Entofarmers Club has the ambition to establish one or more Future Entofarmers Hubs (FEH). An FEH can be set up on-site at research locations or farms, where entofarmers can test, research and commercialise controlled entofarming. The FEH enhances technological development and commercialisation in the Controlled Environment Entoculture (CEE) sector. The FEH is a total controlled environment entoculture demonstrator system. By setting up an FEH, this demonstrator, and the people and companies involved, become part of the global Entofarmers Club. </strong></p>
          </Container>
        </Section>

        <Section style={tw`text-left`}>
          <Container>
            <h3>Why do we need Future Entofarmers Hubs?</h3>
            <p>Controlled Environment Entoculture (CEE) is focussed on creating the most optimal production using the latest technology. This is a multi-disciplinary effort which can lead to miscommunication and individual developments. A system, collaboration and location is needed where a holistic overview and integrated developent is possible. This way the farmers, the technology and the commercialisation can reach their full potential.</p>
            <p>The core of CEE is the availability of data. Data is needed for each part of the system and production chain to evaluate the impact of changes in the controlled environment and new rechnological developments. Whether you are a grower, a technology developer or a food producer.</p>
          
            <h3>Future Entofarmers Hub overview</h3>
            <p>In the Future Entofarmers Hub the users can create a fully controlled environemnt. This is needed to create the optimal growing conditions based on predefined prameters. The FEH controls for instance light, CO2, temperature, humidity, water quality, pH levels, and nutrients.</p>
            <p>The FEH is created with calibration and control in mind. Variance in one or more of these inputs can have a significant impact on insect growth. New technology development for CEE systems focusses on improving the efficiency of production and yield from insects. A full evaluation of how new technology impacts cultivation and integrates within the current growing system is required for the success of a product in this sector.</p>
            <p>Vertical / Indoor farming combines the controlled growing of insects indoors, in stacked grow-beds, under artificial lights and within a controlled environment. There is increased interest in the benefit these systems can offer in the production of food. However, the research and development of products such as species varieties and technologies specific to this cultivation method are limited by opportunities for evaluation within a relevant demonstrator system. </p>
          
            <h3>Creating a Future Entofarmers Hub</h3>
            <strong><p>The Future Entofarmers Hub will be developed in partnership with relevant parties that are willing to work on open innovation.</p>
            <p>The Hub is an initiative of <a href="https://urbanlink.nl" target="_blank" rel="noopener noreferrer">urbanlink.nl</a> to assist in the advancement of CEE technologies and research and to stimulate a source of sustainable protein for the world. This capability offers the flexibility to test and evaluate new products, integrate technologies and undertake research into new areas such as the assessment of new varieties, substrates and nutrient mixes, and light recipes.</p></strong>
          </Container>
        </Section>

        <Section style={tw`bg-primary`}>
          <Container>
            <h3>Collaboration</h3>
            <p>Are you interested in collaborating? Either as a grower, a research institution, a food producer or a technology suppliers?</p>
            <p>Are you motivated to share your knowledge in an open innovation project?</p>
            <a 
              style={tw`text-black text-xl rounded border border-black border-solid px-2 py-4 my-8 inline-block hover:bg-black hover:text-white`} 
              href="mailto:arn@urbanlink.nl"
              rel="noopener noreferrer"
            >Get in contact now!</a>
          </Container>
        </Section>

      </Layout>
    )
  }
}

const Section = styled.section`${tw`py-16 text-center text-xl`}`
const Title = styled.h1`${tw`text-3xl lg:text-6xl`}`
const Subtitle = styled.h2`${tw`text-xl lg:text-4xl font-thin`}`